(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventBookingController', TropEventBookingController);

    TropEventBookingController.$inject = ['$state', 'TropEventBooking', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function TropEventBookingController($state, TropEventBooking, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;

        vm.filterSearch = pagingParams.search;
        vm.filterStatus = pagingParams.bookingStatus;
        vm.filterTicketStatus = pagingParams.ticketStatuses;
        vm.filterMailStatus = pagingParams.mailStatus;

        vm.search = search;
        vm.pressEnter = pressEnter;
        vm.formatMailStatus = formatMailStatus;

        loadAll();

        function getGenericFilter(){
        	return {
                search: vm.filterSearch,
                bookingStatus: vm.filterStatus,
                mailStatus: vm.filterMailStatus,
                ticketStatuses: vm.filterTicketStatus
        	}
        }

        function loadAll () {
        	var genericFilter=getGenericFilter();
            TropEventBooking.query($.extend({}, genericFilter, {
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort()
            }), onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tropEventBookings = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
        	var genericFilter=getGenericFilter();
            $state.transitionTo($state.$current, $.extend({}, genericFilter, {
                page: vm.page,
                itemsPerPage: parseInt(vm.itemsPerPage),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            }));
        }

        function pressEnter(event){
            if(event.keyCode === 13){
                search();
            }
        }

        function search(){
            vm.transition();
        }

        function formatMailStatus(mailStatus) {
            const statusMap = {
                "CLICKED": "success",
                "OPENED": "success",
                "DELIVERED": "info",
                "ACCEPTED": "info",
                "CREATED": "gray",
                "TEMPORARY_FAILED": "warning",
                "PERMANENT_FAILED": "danger",
                "SPAM": "danger",
                "NOT_EXIST": "danger",
                "UNKNOWN": "danger",
                "REJECTED": "danger"
            };
            return statusMap[mailStatus] != null ? statusMap[mailStatus] : "gray";
        }
    }
})();
